<template>
  <div class="search-results">
    <div class="container">
      <div class="row">
        <div class="col">
          <h2 class="page-title" v-if="this.q.length > 0">Results for: {{this.q}}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col">
          
          <div class="row results-list" v-if="apps.length > 0">
            <div v-for="app in apps" :key="app.trackId" class="col col-12 col-lg-6">
              <div class="app-box-wrapper">
                <AppBox :app="app"/>
              </div>
            </div>
          </div>

          <div class="row" v-else>
            <div class="col loading-spinner-wrapper">
              <LoadingSpinner/>
            </div>
          </div>

        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import AppBox from '@/components/AppBox.vue'
import iTunes from '@/services/itunes'
import {Circle} from 'vue-loading-spinner'

const itunes = new iTunes();

export default {
  components: {
    AppBox,
    LoadingSpinner: Circle
  },
  data() {
    return {
      q: '',
      apps: []
    }
  },
  methods: {
    search(query) {
      this.apps = [];
      this.q = '';
      itunes
        .search(query)
        .then((results) => {
          this.q = query;
          this.$set(this, 'apps', results);
      });
    }
  },
  watch: {
    '$route.query.q': function (q) {
      this.search(q);
    }
  },
  mounted() {
    this.search(this.$route.query.q);
  }
}
</script>

<style lang="scss">
.results-list {
  padding: 0;

  li {
    list-style: none;
  }
}
.loading-spinner-wrapper {
  min-height: 30vh;
}
.app-box-wrapper {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 15px;
}
</style>

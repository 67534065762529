<template>
  <div class="app-box">
    <div class="row no-gutters app-box__row-wrapper">
      <div class="col col-auto app-box__img-wrapper">
        <img v-lazy="app.artworkUrl100" alt="" class="app-box__img">
      </div>
      <div class="col app-box__info-wrapper">
        <h3 class="app-box__title text-truncate">{{ app.trackName }}</h3>
        <div class="app-box__specs">
          <a :href="'https://itunes.apple.com/it/genre/id' + app.primaryGenreId" target="_blank">{{ app.primaryGenreName }}</a>
          <span>•</span>
          <a :href="app.artistViewUrl" target="_blank">{{ app.artistName }}</a>
        </div>
        <p class="app-box__price">{{ app.formattedPrice }}</p>
        <p class="app-box__desc">{{ app.description.substring(0, 64) + (app.description.length > 64 ? '&hellip;' : '')}}</p>
      </div>
      <div class="col col-auto app-box__btn-wrapper">
        <InstlBtn :app="app"/>
      </div>
    </div>
    <div class="row no-gutters app-box__screen-row clearfix">
      
      <template v-if="isLandscapeScreen(app.screenshotUrls[0])">
        <div class="app-box__screen-wrapper landscape">
          <img 
            v-lazy="app.screenshotUrls[0]" 
            alt="" 
            class="app-box__screen">
        </div>
      </template>

      <template v-else>
        <div class="app-box__screen-wrapper portrait">
          <img 
            v-for="screenUrl in app.screenshotUrls.slice(0, 3)"
            :key="screenUrl"
            v-if="!isLandscapeScreen(screenUrl)"
            v-lazy="screenUrl" 
            alt="" 
            class="app-box__screen">
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import InstlBtn from '@/components/InstlBtn.vue'

export default {
  props: {
    app: Object
  },
  components: {
    InstlBtn
  },
  methods: {
    isLandscapeScreen(screenUrl) {
      const pattern = /.*\/(\d+)x(\d+).*\.\w{3,4}/;
      let [, sizeW, sizeH] = screenUrl.match(pattern);
      
      if ((sizeW && !isNaN(sizeW = parseInt(sizeW))) && (sizeH && !isNaN(sizeH = parseInt(sizeH)))) {
        return sizeW > sizeH;
      }else {
        return false;
      }
    }
  }
}
</script>

<style lang="scss">

.app-box {
  $root: &;

  &__row-wrapper {
    margin-bottom: 15px;
  }

  // &__img-wrapper { }

  // &__price { }

  &__img {
    width: 64px;
    height: 64px;
    border: 1px solid #CDCED2;
    border-radius: 15px;
  }

  &__info-wrapper {
    &.col {
      padding: 0 15px;
    }
  }

  &__title {
    font-size: 1.5rem;
    max-width: 256px;
    
    @media (min-width: 1200px) {
      max-width: 160px;
    }

    @media (min-width: 1400px) {
      max-width: 226px;
    }
  }

  &__desc {
    margin-bottom: 0;
    font-size: 0.8125rem;
    line-height: 1rem;
    word-break: break-all;
  }

  &__specs {
    margin-bottom: .5rem;

    span {
      display: inline-block;
      margin: 0 5px;
      color: #CDCED2;
    }
  }

  &__btn-wrapper {
    display: flex;
    align-items: center;
  }

  &__btn {
    background: #007AFF;
    color: #fff;
    border-radius: 30px;
    line-height: 26px;
    display: inline-block;
    padding: 0 10px;
    font-size: 16px;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  &__screen-row {
    position: relative;

    &::after {
      padding-bottom: 58.27%;
    }
  }

  &__screen-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
      justify-content: center;

    // &.landscape { }

    &.portrait {
      justify-content: space-evenly;
    }
  }

  &__screen {
    height: 100%;
    width: auto;
    max-width: 100%;
    border-radius: 15px;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}

</style>
